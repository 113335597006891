<template>
  <section id="log-masuk">
    <h2>Sila Log Masuk</h2>
    <div class="secondary-outline alert-box">
      <h3>Maaf. Sila daftar masuk.</h3>
      <p>Anda perlu mendaftar masuk untuk menggunakan sistem ini.</p>
      <button id="butang-log-masuk" class="btn secondary-box" @click="logMasuk">Log Masuk</button>
    </div>
    <div class="danger-box alert-box" v-if="success">
      Log masuk berjaya.
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { auth } from '../firebase'
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

export default defineComponent({
  data() {
    return {
      success: false,
    }
  },
  methods: {
    logMasuk() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(() => {
          this.success = true;
          this.$router.push("/")
        })
        .catch(console.log)
    }
  }
})
</script>


<style lang="scss" scoped>
.alert-box {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 5px;
  border: 1px solid rgb(138, 90, 0);
  text-align: left;

  h3 {
    margin: 0;
  }
}

#log-masuk {
  display: grid;
  align-items: center;
  justify-content: center;
}
</style>